import React from "react";

import "tailwindcss/tailwind.css";

// Import Sections
import Brb from './components/section/Brb';

function Main() {
  return (
    <div className="App max-w-screen-xl m-auto h-full flex items-center text-center md:text-left justify-center md:justify-start">
      <div className="flex flex-col flex-col-reverse lg:flex-row">
        <div className="main-content">
          <div className="main grid grid-cols-1">
            <Brb />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Main;
