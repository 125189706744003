import React from 'react';
import MySiteLogo from '../atoms/SiteLogo';
import MySocials from '../atoms/Socials'

export default function Brb() {
  return (
    <div>
      <h1 className="mb-3 txt-lg text-5xl font-bold leading-none">Stay tuned.</h1>
      <h3 className="mb-3">pamdayne will be right back.</h3>
      <div className="flex flex-column items-baseline">
        <MySiteLogo />
        <MySocials />
      </div>
    </div>
  )
}
