import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faLinkedinIn, faCodepen, faTwitter, faGithub } from "@fortawesome/free-brands-svg-icons";
import data from '../../data/data.json';

export default function MySocials() {
  const socialList = (_social) => {
    let html = []

    for (const item in _social) {
      let socialName = Object.keys(_social[item]).toString()
      let socialUrl = Object.values(_social[item]).toString()

      html.push(
        <a href={socialUrl} target="_blank" rel="noreferrer noopener" key={socialName}
          className="text-xl transition ease-in-out delay-50 hover:-translate-y-1 hover:scale-110 duration-300">
          {
            //inline switch case
            {
              'email': <FontAwesomeIcon icon={faEnvelope} />,
              'linkedin': <FontAwesomeIcon icon={faLinkedinIn} />,
              'twitter': <FontAwesomeIcon icon={faTwitter} />,
              'github': <FontAwesomeIcon icon={faGithub} />,
              'codepen': <FontAwesomeIcon icon={faCodepen} />,
            }[socialName]
          }
        </a>)
    }
    return (html)
  }

  return (
    <div className="flex justify-start gap-x-7 lg:gap-x-10 lg:ml-0 mb-0">
      {socialList(data.social)}
    </div>
  )
}
